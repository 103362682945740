import React, { useEffect, useState, useRef, useMemo } from "react";
import IMAGES from "../Middleware/images";
import "../Assets/Styles/step-form.css";
import apiHelper from "../Helper/api-helper";
import ClearIcon from "@mui/icons-material/Clear";
import ReactLoader from "../ControlComponents/react-loader";
import DEVELOPMENT_CONFIG from "../Helper/config";
import Steps from "../Components/steps";
import ERR_MESSAGE from "../Helper/error-helper";
import titles from "../Helper/title";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Helmet } from "react-helmet";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import HeaderSignUp from "../Components/header-signUp";
import { useNavigate, useSearchParams } from "react-router-dom";
import ROUTES from "../Middleware/routes";
import TutorialModal from "./tutorial-modal";
import Footer from "../Components/footer";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
function StepForm() {
  const [field, setField] = useState('');
  const [catId, setCatId] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [steps, setSteps] = useState(1);
  const [roleList, setRoleList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [categoriesArr, setCategoriesArr] = useState([
    {
      cat_id: "",
      sub_cat_id: [],
    },
  ]);
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sub_cat, setSub_cat] = useState([]);
  const [errors, setErrors] = useState("");
  const [fields, setFields] = useState({
    userName: searchParams.get("name"),
    email: searchParams.get("email"),
    role: "",
    password: "",
    place: "",
    latitude: "",
    longitude: "",
    experience: "",
    subexpArr: [],
    subsplArr: [],
    interests: "",
    rolesArr: [],
    tap: "",
    tapArr: [],
    speciality: "",
    experiencesArr: [],
    specialityArr: [],
    interestsArr: [],
    rolesArr: [],
  });

  const dataArray = categoryList[steps - 2]?.subcatdata ? categoryList[steps - 2]?.subcatdata : categoryList[steps - 2]?.osubcatdata

  const [type, setType] = useState("password");
  const [eye_icon, setEye_icon] = useState(IMAGES.EYE_HIDE);
  const [msg, setMsg] = useState("reveal password");
  const [chipActiveArr, setChipActiveArr] = useState([]);

  const chipClick = (item, step) => {

    setField('')
    const chip = item?.id;
    const index = steps - 2;
    const updatedChipActiveArr = [...chipActiveArr];

    updatedChipActiveArr[index][chip] = !updatedChipActiveArr[index][chip];
    setChipActiveArr(updatedChipActiveArr);

    if (item) setLoading(false);

    let updatedArray;
    if (step === "proceedStep") {
      const storedCategoriesArr = JSON.parse(localStorage.getItem("categoriesArr"));
      updatedArray = storedCategoriesArr.map((cat, i) => {
        if (i === index) {
          return {
            ...cat,
            sub_cat_id: updatedChipActiveArr[index][chip]
              ? [...cat.sub_cat_id, chip]
              : cat.sub_cat_id?.filter((subItem) => subItem !== chip),
          };
        }
        return cat;
      });
    } else {
      updatedArray = categoriesArr.map((cat, i) => {
        if (i === index) {
          return {
            ...cat,
            sub_cat_id: updatedChipActiveArr[index][chip]
              ? [...cat.sub_cat_id, chip]
              : cat.sub_cat_id?.filter((subItem) => subItem !== chip),
          };
        }
        return cat;
      });
    }

    localStorage.setItem("updatedArray", JSON.stringify(updatedArray));
    setCategoriesArr(updatedArray);

    if (step === "lastStep") {
      postAPI()
    }
  };



  async function textApi(catId, step) {
    let data = {
      "name": field,
      "cat_id": catId
    };
    setLoading(true);
    let result = await apiHelper.postRequest("auth/create-subcategory", JSON.stringify(data));
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {



      await getAPI(result.body, step)

      // chipClick(result.body,step)
    } else {
      setLoading(false);
    }
  }

  const getMatchingObject = (fieldName) => {
    const dataArray = categoryList[steps - 2]?.subcatdata
      ? categoryList[steps - 2]?.subcatdata
      : categoryList[steps - 2]?.osubcatdata;

    if (!dataArray) return null;
    return dataArray.find(item => item.name === fieldName) || null;
  };

  const nextStep = (e) => {
    e.preventDefault();
    setField('')

    if (field?.trim().length > 0 && field != "," && steps != categoryList?.length + 1) {
      const matchingObject = getMatchingObject(field);
      if (!matchingObject) {
        textApi(catId, "proceedStep")
      }
      else {
        chipClick(matchingObject)
      }
    }

    if (steps <= categoryList?.length) {
      setSteps(steps + 1);
      for (let i = 0; i < categoriesArr?.length; i++) {
        if (categoryList[steps - 1]?.id == categoriesArr[i]?.cat_id) return;
      }
      const newObj = {
        cat_id: categoryList[steps - 1]?.id,
        sub_cat_id: [],
      };
      console.log("activeArrList::::", newObj)
      setCategoriesArr((prevDataArray) => [...prevDataArray, newObj]);

    }

    else if (steps == categoryList?.length + 1) {

      if (field?.trim().length > 0) {
        const matchingObject = getMatchingObject(field);
        if (!matchingObject) {
          textApi(catId, "lastStep")
        }
        else {
          chipClick(matchingObject)
        }
      }
      else {
        postAPI()
      }
    }
  };

  const backStep = (e) => {
    e.preventDefault();
    if (steps > 1) {
      setSteps(steps - 1);
      setField("")
    }
  };

  const inputRef = useRef();
  let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";
  const handlePlaceChanged = () => {
    const [place] = inputRef?.current?.getPlaces();

    if (place) {
      setFields({
        ...fields,
        place: place.formatted_address,
        latitude: `${place.geometry.location.lat()}`,
        longitude: `${place.geometry.location.lng()}`,
      });
    }
  };

  const show = () => {
    if (type === "password") {
      setType("text");
      setEye_icon(IMAGES.EYE_SHOW);
      setMsg("hide password");
    } else {
      setType("password");
      setEye_icon(IMAGES.EYE_HIDE);
    }
  };

  const handleChange = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });
    if (name === "place") {
      setFields({
        ...fields,
        [name]: value,
        latitude: "",
        longitude: ""
      });
    }

  };

  const [filteredOptions, setFilteredOptions] = useState(roleList);
  const handleInputChange = (event, newValue) => {
    setErrors("");
    const filteredRoles = roleList?.filter(role => role.toLowerCase().includes(newValue.toLowerCase()));
    setFilteredOptions(filteredRoles);
    setFields(prevFields => ({
      ...prevFields,
      role: newValue
    }));
  };


  const handleSelectionChange = (event, newValue) => {
    setErrors("")
    if (event.key === 'Enter' || event.code === 'NumpadEnter') {
      setOpenDropdown(false);
    }
    setFields(prevFields => ({
      ...prevFields,
      role: newValue,
    }));
    setOpenDropdown(false);
  };

  const handleChange2 = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
    if (e.target.value === "" || roleList.includes(value)) {
      setOpenDropdown(false)
    } else {
      setOpenDropdown(true);
    }

  };


  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    const {
      role,
      email,
      userName,
      place,
      longitude,
      latitude,
      password,
    } = fields;
    if (!userName || userName.trim().length === 0) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMPTY_FULLNAME;
    } else if (userName.length < 3) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME;
    }
    else if (userName.length > 30) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
    }
    else if (emojiRegex.test(userName)) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMOJI;
    }
    else if (userName.length > 30) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME1;
    }

    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
    } else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    if (!place || place.trim().length === 0) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.LOCATION_EMPTY;
    }

    else if (!longitude || longitude.trim().length === 0 || !latitude || latitude.trim().length === 0) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
    }
    else if (emojiRegex.test(place)) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.EMOJI;
    }
    if (!password || password.trim().length === 0) {
      formIsValid = false;
      errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY;
    } else if (passwordReg.test(password) === false) {
      formIsValid = false;
      errors["password"] = ERR_MESSAGE.INVALID_PASSWORD;
    }
    if (!role || role.trim().length === 0) {
      formIsValid = false;
      errors["role"] = ERR_MESSAGE.ROLE_EMPTY;
    }
    else if (emojiRegex.test(role)) {
      formIsValid = false;
      errors["role"] = ERR_MESSAGE.EMOJI;
    }

    setErrors(errors);
    return formIsValid;
  };

  async function getVerifyAPI() {
    setLoading(true)
    let result = await apiHelper.getRequest("auth/check-account?email=" + searchParams.get("email"))
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false)
    }
    else {
      navigate(ROUTES.ERROR_PAGE)
      setLoading(false)
    }
  }

  useEffect(() => {
    localStorage.removeItem("updatedArray")
    getVerifyAPI()
  }, [])

  const error = (msg) => {
    toast.success(msg,
      {
        autoClose: 5000,
      });
  }
  async function postAPI() {
    const storedUpdatedArray = JSON.parse(localStorage.getItem("updatedArray"));
    const updatedCategory = storedUpdatedArray?.filter(item => item.sub_cat_id.length > 0);
    let data = {
      name: fields.userName,
      role: searchParams.get("role"),
      email: fields.email,
      password: fields.password,
      designation: fields.role,
      location: fields.place,
      latitude: fields.latitude,
      longitude: fields.longitude,
    };
    if (searchParams.get("role") == 1) {
      data.categoriesArr = updatedCategory
    }

    setLoading(true);
    let result = await apiHelper.postRequest("auth/complete-profile", JSON.stringify(data));
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      localStorage.setItem("token", result?.body?.token);
      localStorage.setItem("role", result?.body?.user?.role);
      localStorage.setItem("id", result?.body?.user?.id);
      if (result?.body?.user?.role == 2) {
        navigate(ROUTES.ORGANIZATION_CATEGORY);
      }
      if (result?.body?.user?.role == 1) {
        // navigate(ROUTES.SEARCH_DATABASE);
        navigate(ROUTES.PROFILE_CONFIRMATION);
      }
      localStorage.removeItem("updatedArray")
      localStorage.removeItem("categoriesArr")
      setLoading(false);
    } else {
      error(result.message)
      setLoading(false);
    }
  }


  async function getAPI(item, step) {
    setLoading(true);
    let emailParams = searchParams.get("email")
    if (emailParams) {
      let result = await apiHelper.getRequest(
        "auth/category-list?email=" + searchParams.get("email")
      );
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setCategoryList(result?.body);
        if (step == "proceedStep") {
          for (let i = 0; i < categoriesArr?.length; i++) {
            if (categoryList[steps - 1]?.id == categoriesArr[i]?.cat_id) return;
          }
          const newObj = {
            cat_id: categoryList[steps - 1]?.id,
            sub_cat_id: [],
          };
          setCategoriesArr((prevDataArray) => [...prevDataArray, newObj]);
          localStorage.setItem("categoriesArr", JSON.stringify([...categoriesArr, newObj]));


        }
        const updatedArray = result?.body?.map((category) => {
          const newObj = { ...category?.subcatdata };
          const subcatDataObject = {};
          for (const key in newObj) {
            subcatDataObject[newObj[key].id] = false
          };
          return subcatDataObject;
        })
        setChipActiveArr(updatedArray)
        if (item) chipClick(item, step)
        if (!item) setLoading(false);
      } else {
        setLoading(false);
      }
      verifyToken()
    }
    else {
      navigate(ROUTES.NO_DATA)
    }

  }

  async function verifyToken() {
    setLoading(true);
    let tokenParams = searchParams.get("token")
    if (tokenParams) {
      let data = JSON.stringify({
        "token": tokenParams,
      });
      let result = await apiHelper.postRequest("auth/get-user-by-token", data);

      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setFields({
          ...fields,
          place: result?.body?.location,
          latitude: result?.body?.latitude,
          longitude: result?.body?.longitude,
          role: result?.body?.designation,
        });
        var cat_list = [], activeArrList = chipActiveArr;

        // console.log("cat_list")
        if (categoriesArr[0].cat_id == "") {

          result?.body?.usercatdata.forEach((el, index) => {
            var sub_cat_id = [], activeArr = {}
            el?.osubcatdata?.filter(res => res.selected != null).forEach(r => {
              sub_cat_id.push(r.id)
              activeArr = { ...activeArr, [r.id]: true }
              const index = steps - 2;
              // chipClick({id: r.id, cat_id:r.cat_id, name: r.name})
            })


            if (sub_cat_id.length > 0) {
              activeArrList.push(activeArr);
              cat_list.push({ cat_id: el.id, sub_cat_id: sub_cat_id })
            }
            else {
              activeArrList.push({});
            }
          });


          if (cat_list.length > 0) {

            setChipActiveArr(activeArrList);
            setCategoriesArr(cat_list);
            localStorage.setItem("updatedArray", JSON.stringify(cat_list));
          }
        }
        console.log(result?.body)
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    else {
      navigate(ROUTES.NO_DATA)
    }
  }
  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  useEffect(() => {

    getAPI();
    getRoleAPI()

    console.log('first')
  }, [state]);
  console.log(state, "state");
  async function getRoleAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest(`organization/role-list`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setRoleList(result?.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  console.log(field, 'Diksha')

  return (
    <>
      <Helmet>
        <title>{titles.signUp}</title>
      </Helmet>
      <>
        <div className='loader'>
          {isLoading && <ReactLoader />}
        </div>
        {steps === 1 && (
          <div>
            <section className="join-us-section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="join-us-top text-center">
                      <div className="join-us-head d-flex justify-content-center mt-3 mb-2">
                        <img
                          src={IMAGES.JOIN_LOGO}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <p className="join-text text-center">
                        Speed up collaboration by easily finding coworkers who
                        have knowledge, expertise, or interests
                        <br /> that will help you do your job, make useful
                        connections, and make work life better.
                      </p>
                      <div className="join-link mb-4">
                        <img
                          src={IMAGES.HAPTAPT_LOGO}
                          alt=""
                          className="img-fluid my-4"
                        />
                        <p>
                          You’re accepting an invitation sent to
                          <br />
                          <a href="" className="pink-text">
                            {searchParams.get("email")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="details-page">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="details-head mt-5 mb-4 text-center">
                      <a href="" className="step-btn ">
                        Step {steps}/{categoryList.length + 1}
                      </a>
                    </div>
                    <div className="details-head my-4 text-center">
                      <h2 className="my-4">
                        Please fill out the below details
                      </h2>
                      <div className="col-lg-6 mx-auto">
                        <div className="text-start position-relative">
                          <TextField
                            fullWidth
                            label="Your name"
                            variant="outlined"
                            sx={{
                              "& fieldset": {
                                border: "2px solid #231f20",
                                borderRadius: "30px",
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#231f20;",
                              },
                            }}
                            name="userName"
                            onChange={handleChange}
                            value={fields?.userName?.replace(/-/g, ' ')}
                          />

                          {errors?.userName && <span className="err_msg">{errors.userName}</span>}
                        </div>
                        <div className="my-4 text-end">
                          <TextField
                            fullWidth
                            label="Email address"
                            variant="outlined"
                            sx={{
                              "& fieldset": {
                                border: "2px solid #231f20",
                                borderRadius: "30px",
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#231f20;",
                              },
                            }}
                            name="email"
                            onChange={handleChange}
                            value={searchParams.get("email")}
                          />
                          {errors.email &&
                            <span className="err_msg">{errors.email}</span>}
                        </div>

                        <div className="my-4 text-end">
                          <LoadScript
                            googleMapsApiKey={key}
                            libraries={["places"]}
                          >
                            <StandaloneSearchBox
                              onLoad={(ref) => (inputRef.current = ref)}
                              onPlacesChanged={handlePlaceChanged}
                            >
                              <TextField
                                fullWidth
                                label="Location"
                                variant="outlined"
                                sx={{
                                  "& fieldset": {
                                    border: "2px solid #231f20",
                                    borderRadius: "30px",
                                  },
                                  "& .MuiInputLabel-shrink": {
                                    color: "#231f20;",
                                  },
                                }}
                                name="place"
                                onChange={handleChange}
                                value={fields.place}
                                placeholder=""
                              />
                            </StandaloneSearchBox>
                            {errors?.place && <span className="err_msg">{errors.place}</span>}
                          </LoadScript>
                        </div>
                        <div className="my-4 text-end role-custome">
                          <Autocomplete
                            fullWidth
                            options={filteredOptions}
                            onBlur={closeDropdown}
                            getOptionLabel={(option) => option}
                            value={fields?.role}
                            onInputChange={handleInputChange}
                            onChange={handleSelectionChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.code === "NumpadEnter") {
                                setOpenDropdown(false);
                              }
                            }}
                            open={openDropdown}
                            disableClearable
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                onChange={(e) => handleChange2(e)}
                                label="Role/Title"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment></React.Fragment>
                                  ),
                                }}
                                onFocusCapture={() => {
                                  setOpenDropdown(false);
                                }}
                                sx={{
                                  "& fieldset": {
                                    border: "2px solid #231f20",
                                    borderRadius: "30px",
                                  },
                                  "& .MuiInputLabel-shrink": {
                                    color: "#231f20;",
                                  },
                                }}
                              />
                            )}
                          />

                          {errors?.role && <span className="err_msg">{errors.role}</span>}
                        </div>

                        <div className="my-4 text-end position-relative forgot-input">
                          <TextField
                            type={type}
                            fullWidth
                            label="Enter password"
                            variant="outlined"
                            sx={{
                              "& fieldset": {
                                border: "2px solid #231f20",
                                borderRadius: "30px",
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#231f20;",
                              },
                            }}
                            name="password"
                            onChange={handleChange}
                            value={fields.password?.trim()}
                          />
                          {errors?.password && <span className="err_msg">{errors.password}</span>}
                          <img
                            src={eye_icon}
                            style={{ cursor: "pointer" }}
                            onClick={show}
                            className="img-fluid hiden-eye "
                            title={msg}
                          />
                        </div>
                        <div className="text-center position-relative submit-head d-flex align-items-center gap-4 step-one-bottom justify-content-center mt-3">

                          <button
                            type="submit"
                            className="proceed-yellow-btn position-relative d-flex align-items-center"
                            onClick={(e) => {
                              e.preventDefault();
                              if (handleValidation()) {
                                if (searchParams.get("role") == 2) {
                                  postAPI()
                                }
                                else if (searchParams.get("role") == 1) {
                                  if (categoryList.length > 0) {
                                    window.scrollTo(0, 0);
                                    const storedUpdatedArray = JSON.parse(localStorage.getItem("updatedArray"));
                                    if (!storedUpdatedArray) {

                                      const newDataArray = categoriesArr?.map(
                                        (item) => ({
                                          ...item,
                                          cat_id: categoryList[steps - 1]?.id,
                                          sub_cat_id: [],
                                        })
                                      );
                                      setCategoriesArr(newDataArray);
                                      setSteps(steps + 1);
                                    }
                                    else {
                                      window.scrollTo(0, 0);
                                      setSteps(steps + 1);
                                    }
                                    return;
                                  }
                                  else {
                                    postAPI()
                                  }
                                }
                              }
                            }}
                          >
                            Proceed{" "}
                            <img
                              src={IMAGES.ARROW_GREY}
                              alt=""
                              className="img-fluid ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
      {steps > 1 && (
        <>
          <HeaderSignUp />
          <Steps
            setField={setField}
            setState={setState}
            state={state}
            field={field}
            setChipActiveArr={setChipActiveArr}
            chipActiveArr={chipActiveArr}
            setCatId={setCatId}
            catId={catId}
            steps={steps}
            textApi={textApi}
            chipClick={chipClick}
            sub_cat={sub_cat}
            setSub_cat={setSub_cat}
            categoriesArr={categoriesArr}
            setCategoriesArr={setCategoriesArr}
            setSteps={setSteps}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
          />
          <div className="text-center position-relative submit-head d-flex align-items-center gap-4 step-one-bottom justify-content-center">
            <button
              type="button"
              className="back-blue-btn position-relative d-flex align-items-center"
              onClick={backStep}
            >
              <img
                src={IMAGES.LEFT_ARROW}
                alt=""
                className="img-fluid me-2"
              />{" "}
              Back
            </button>
            <button
              type="button"
              className="proceed-yellow-btn position-relative d-flex align-items-center"
              onClick={(e) => nextStep(e)}
            >
              Proceed{" "}
              <img
                src={IMAGES.ARROW_GREY}
                alt=""
                className="img-fluid ms-2"
              />
            </button>
          </div>
        </>
      )}
      {open == true && (
        <>
          <TutorialModal open={open} setOpen={setOpen} />
        </>
      )}
      <ToastContainer rtl progressClassName="progress-bar-cus" />

      <Footer />
    </>
  );
}

export default StepForm;
